import { Thumbnail } from 'mm-ui-components/dist/src/base/partials/image/image.types';
import { Article, Image } from '../template.state.types';
import {
  FINITE_SCROLL_ARTICLES_PENDING,
  INJECT_ARTICLE_BLOCK_BY_INDEX_ARRAY,
  RECEIVED_FINITE_SCROLL_ARTICLES_SUCCESSFULLY,
  RECEIVED_FINITE_SCROLL_ARTICLES_UNSUCCESSFULLY,
} from './postPage.actions';
import { LoadMoreAction, RECEIVED_POSTS_SUCCESSFULLY, RECEIVED_POSTS_UNSUCCESSFULLY } from '../loadMore.actions';
import { FiniteScrollAction } from './types';

type Block = {
  type: string;
  [key: string]: any;
  dataId: string;
};

export type Body = Array<Block>;

export interface Cover {
  image: Image;
  media: Media | null;
}

interface Media {
  html: string;
  type: string;
  videoId?: string;
}

interface ShareConfigItem {
  shareURL: string;
  src: string;
  type: string;
}

export type ShareConfig = Array<ShareConfigItem>;

export interface Recommendation {
  articles: Array<Article>;
  next: string;
  displayName: string;
  text: string;
  link: string;
}

export interface InThisStory {
  slug: string;
  displayName: string;
  link: string;
}

export type RecommendationNames = 'recommendation1' | 'recommendation2';

export interface SponsorData {
  brandName: string;
  text: string;
  image: Image;
}

export interface AuthorData {
  name: string;
  link?: string;
  image?: Thumbnail | null;
  byline?: string;
  username: string;
}

export interface AuthorWithSocial extends AuthorData {
  socialLinks?: {
    alt: string;
    src: string;
    url: string;
  }[];
  bio?: string;
}

export type AuthorsList = AuthorData[];

export interface Slideshow {
  currentSlide: number;
  totalSlides: number;
  prevText: string;
  nextText: string;
  ofText: string;
  prevLink: string;
  nextLink: string;
  slidesLinks: Record<string, string>;
}

export interface SlideshowAMP {
  currentSlide: number;
  totalSlides: number;
  prevText: string;
  nextText: string;
  ofText: string;
  prevLink: string;
  nextLink: string;
  anchorId: string;
}

export interface MainCategory {
  displayName: string;
  link: string;
  isActive: boolean;
  slug?: string;
}

export interface BreadCrumbs {
  homeDisplayName: string;
  homeLink: string;
  categoryDisplayName: string;
  categoryLink: string;
  isActive: boolean;
}

export interface PostPageWithRecommendation {
  body: Body | null;
  recommendation1?: Recommendation;
  recommendation2?: Recommendation;
  finiteScrollArticles?: Array<any>;
  finiteScrollArticlesURLs?: Array<string>;
}

const INJECTED_BLOCK_TYPE = 'injectedBlock';

interface InjectedBlock {
  index: number;
  block: {
    type: typeof INJECTED_BLOCK_TYPE;
    html: string;
    dataId: string;
    script: string | undefined;
  };
}

export interface InjectArticleBlockByIndexArrayAction {
  type: typeof INJECT_ARTICLE_BLOCK_BY_INDEX_ARRAY;
  blocks: Array<InjectedBlock>;
}

export type PostPageAction = LoadMoreAction<RecommendationNames> | InjectArticleBlockByIndexArrayAction | FiniteScrollAction;

const sortByIndexComparator = (block1: InjectedBlock, block2: InjectedBlock) => {
  if (block1.index < block2.index) {
    return -1;
  }
  if (block1.index > block2.index) {
    return 1;
  }
  return 0;
};

const mergeBlocks = (blocks: Array<InjectedBlock>, body: Body) => {
  let injectedBlocksCounter = 0;
  return blocks.reduce((acc, { index, block }) => {
    const injectedBlockIndex = index + injectedBlocksCounter;
    const bodyWithInjectedBlock = [...acc.slice(0, injectedBlockIndex), block, ...acc.slice(injectedBlockIndex)];
    injectedBlocksCounter += 1;
    return bodyWithInjectedBlock;
  }, body);
};

export const postPageReducer = <T extends PostPageWithRecommendation>(state: T = {} as T, action: PostPageAction) => {
  switch (action.type) {
    case RECEIVED_POSTS_SUCCESSFULLY: {
      return {
        ...state,
        [action.sectionKey]: {
          ...state[action.sectionKey],
          articles: action.articles,
        },
      };
    }

    case RECEIVED_POSTS_UNSUCCESSFULLY: {
      return state;
    }

    case RECEIVED_FINITE_SCROLL_ARTICLES_SUCCESSFULLY: {
      const finiteScrollArticles = state.finiteScrollArticles ? [...state.finiteScrollArticles, ...action.articles] : action.articles;
      return {
        ...state,
        finiteScrollArticles,
        loadingArticles: false,
      };
    }

    case RECEIVED_FINITE_SCROLL_ARTICLES_UNSUCCESSFULLY: {
      return {
        ...state,
        loadingArticles: false,
      };
    }

    case FINITE_SCROLL_ARTICLES_PENDING: {
      return {
        ...state,
        loadingArticles: true,
      };
    }

    case INJECT_ARTICLE_BLOCK_BY_INDEX_ARRAY: {
      const { body } = state;
      const { blocks } = action;
      const sortedBlocks = blocks.sort(sortByIndexComparator);
      const mergeBlocksToBody = mergeBlocks(sortedBlocks, body || []);
      return {
        ...state,
        body: mergeBlocksToBody,
      };
    }

    default:
      return state;
  }
};
