import { Block, idGenerator } from '../idGenerator.utils';
import { RelatedTopicsData } from './createRelatedTopicsData.utils';

export const relatedTopicsBlockType = 'relatedTopics';

export const addRelatedTopicsBlock = (feedRecommendationsData: RelatedTopicsData, body: Array<Block>) => {
    const { title, pages, blockPosition } = feedRecommendationsData;
    const feedRecommendationsBlock = {
        type: relatedTopicsBlockType,
        dataId: idGenerator(),
        title,
        pages,
    };
    if (blockPosition > body.length || blockPosition === body.length) {
        return [...body, feedRecommendationsBlock];
    }
    if (blockPosition > 0) {
        return [...body.slice(0, blockPosition), feedRecommendationsBlock, ...body.slice(blockPosition, body.length)];
    }
    if (blockPosition === 0) {
        return [feedRecommendationsBlock, ...body];
    }
    return body;
};
