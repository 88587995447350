import { Block, idGenerator } from './idGenerator.utils';
import { AdInAmpBody } from './ampAdUnits';

export const adBlockType = 'ad';
export const blockTypesNotCountingForExtendedAds = ['divider', 'title', 'page-break'];

const isBlockCountingForAds = (block: Block, shouldExpandInContentAds: boolean) => {
    return shouldExpandInContentAds ? !blockTypesNotCountingForExtendedAds.find(type => type === block.type) : block.type === 'inline-text';
};

export const getAdsWithIndexes = (body: any, adsInBody: Array<AdInAmpBody>, shouldExpandInContentAds: boolean) => {
    let relevantTypesBlocksCounter = 0;
    const adsWithIndexes: Array<any> = [];

    body.forEach((block: Block, index: number) => {
        if (isBlockCountingForAds(block, shouldExpandInContentAds)) {
            relevantTypesBlocksCounter += 1;
          const adInBody = adsInBody.find(ad => ad.numberOfRelevantBlocksBeforeAd === relevantTypesBlocksCounter);
          if (adInBody) {
              adsWithIndexes.push({
                  adInBody,
                  index,
              });
          }
      }
    });
    return adsWithIndexes;
};

export const addAdsBlocksToBody = (body: Array<Block>, adsInBody: Array<AdInAmpBody>, shouldExpandInContentAds: boolean): Array<Block> => {
    const bodyWithAds = [...body];
    const adsWithIndexes = getAdsWithIndexes(body, adsInBody, shouldExpandInContentAds);

    if (adsWithIndexes.length > 0) {
        let adsBlocksCounter = 0;

        adsWithIndexes.forEach(({ adInBody, index }) => {
            const adBlock = {
                type: adBlockType,
                dataId: idGenerator(),
                ...adInBody,
            };
        const adIndex = index + adsBlocksCounter;
        bodyWithAds.splice(adIndex + 1, 0, adBlock);
        adsBlocksCounter += 1;
        });
    }
    return bodyWithAds;
};
