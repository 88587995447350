import { mandatory, nonMandatory } from '../../../storeFromRequestParamsValidation';
import { createAspectRatioData } from './createAspectRatioData';
import { createImage, createPathWithExtension } from '../../../articles.utils';
import { Block, idGenerator } from './idGenerator.utils';
import { addAdsBlocksToBody } from './addAdsBlocksToBody.utils';
import { addRelatedPostsBlock } from './relatedPosts/addRelatedPostsBlock.utils';
import { RelatedPostsData } from './relatedPosts/createRelatedPostsData.utils';
import { RelatedTopicsData } from './relatedTopics/createRelatedTopicsData.utils';
import { addRelatedTopicsBlock } from './relatedTopics/addRelatedTopicsBlock.utils';
import { AdInAmpBody } from './ampAdUnits';
import { updateTableOfContentsBlock } from './updateTableOfContentsBlock';

interface MediaIdProps {
  mediaId?: string;
  originalEmbedUrl?: string;
}

const getMediaId = (type: string) => (value: MediaIdProps) => {
  switch (type) {
    case 'playbuzz':
    case 'facebook':
      return nonMandatory(value.originalEmbedUrl);
    default:
      return nonMandatory(value.mediaId);
  }
};

export const createPageBodyData = (
  imagesCdnHost: string,
  adsInBody: Array<AdInAmpBody> | null,
  relatedPosts: RelatedPostsData | null,
  slideshowParametersForRenderingOnlySpecificSlide: {
    currentSlide: number;
    totalSlides: number;
  } | null,
  relatedTopics: RelatedTopicsData | null,
  shouldExpandInContentAds = false,
) => (receivedBody: any): Array<object> => {
  let slideNumber = 1;
  let tableOfContentsBlockPosition = -1;
  const createPageData = (body: any): Array<Block> => {
    return body.reduce((flatmap: Array<object>, section: any, currentIndex: number) => {
      const type = mandatory(section.type, '');

      if (slideshowParametersForRenderingOnlySpecificSlide) {
        const { currentSlide, totalSlides } = slideshowParametersForRenderingOnlySpecificSlide;
        if (type === 'page-break') {
          slideNumber += 1;
          return flatmap;
        }

        if (totalSlides > 0 && currentSlide !== slideNumber) {
          return flatmap;
        }
      }

      switch (type) {
        case 'jw':
          return [...flatmap, {
            type,
            dataId: idGenerator(),
            html: mandatory(section.value.html, ''),
            videoId: mandatory(section.value.videoId, ''),
            playerId: mandatory(section.value.playerId, ''),
          }];

        case 'image': {
          const aspectRatio = mandatory(section.value.aspectRatio, null, createAspectRatioData);
          return [...flatmap, {
            type,
            dataId: idGenerator(),
            image: mandatory(section.value, {}, createImage(imagesCdnHost, aspectRatio)),
            sizeType: nonMandatory(section.value.sizeType) || 'regular',
          }];
        }

        case 'imported-video': {
          return [...flatmap, {
            type,
            dataId: idGenerator(),
            html: mandatory(section.value.html, ''),
          }];
        }

        case 'raw-html': {
          return [...flatmap, {
            type,
            dataId: idGenerator(),
            html: mandatory(section.value.html, ''),
          }];
        }

        case 'iframeEmbed':
          return [...flatmap, {
            type,
            dataId: idGenerator(),
            html: mandatory(section.value.embedCodeHTMLString, ''),
            src: mandatory(section.value.src, ''),
            height: mandatory(section.value.height, ''),
          }];

        case 'inline-text': {
          return [...flatmap, {
            type,
            dataId: idGenerator(),
            ...mandatory(section.value, {}),
          }];
        }

        case 'list-item': {
          const items = mandatory(section.value.items, [], createPageData);
          return [...flatmap, ...items];
        }

        case 'mmPlayer':
          return [...flatmap, {
            type,
            dataId: idGenerator(),
            html: mandatory(section.value.html, ''),
            mediaId: nonMandatory(section.value.mediaId),
            playerId: nonMandatory(section.value.playerId),
            version: nonMandatory(section.value.version) || '',
          }];

        case 'divider':
          return [...flatmap, {
            type,
            dataId: idGenerator(),
          }];

        case 'page-break':
          return [...flatmap, {
            type,
          }];

        case 'ceros':
          return [...flatmap, {
            type,
            dataId: idGenerator(),
            html: mandatory(section.value.html, ''),
          }];

        case 'mm-content-embed':
          return [...flatmap, {
            type,
            dataId: idGenerator(),
            height: mandatory(section.value.height, ''),
            width: mandatory(section.value.width, ''),
            html: mandatory(section.value.html, ''),
            url: mandatory(section.value.url, ''),
            embedType: nonMandatory(section.value.embedType),
          }];

        case 'imported-table':
          return [...flatmap, {
            type,
            dataId: idGenerator(),
            html: mandatory(section.value.html, ''),
          }];

        case 'imported-embedded-content': {
          const path = mandatory(section.value.imagePath, '');
          const fileExtension = mandatory(section.value.imageFileExtension, '');
          return [...flatmap, {
            type,
            dataId: idGenerator(),
            title: mandatory(section.value.embeddedContentTitle, ''),
            articleUrl: mandatory(section.value.embeddedContentUrl, ''),
            description: nonMandatory(section.value.embeddedContentDescription),
            image: {
              host: mandatory(imagesCdnHost, ''),
              path: createPathWithExtension(path, fileExtension),
              credit: nonMandatory(section.value.imageCredit),
              caption: nonMandatory(section.value.imageCaption),
              alt: nonMandatory(section.value.imageAlt),
            },
          }];
        }

        case 'table-of-contents':
          if (tableOfContentsBlockPosition !== -1) return flatmap;
          tableOfContentsBlockPosition = currentIndex;
          return [...flatmap, {
            type,
            dataId: idGenerator(),
            title: nonMandatory(section.value.title),
          }];

        case 'table':
          return [...flatmap, {
            type,
            dataId: idGenerator(),
            data: mandatory(section.value.data, ''),
          }];

        case 'faq': {
          const items = mandatory(section.value.items, []);
          return [...flatmap, {
            type,
            dataId: idGenerator(),
            question: nonMandatory(items[0]?.value?.text) || '',
            answer: nonMandatory(items[1]?.value?.html) || '',
          }];
        }
        case 'sportradar': {
          return [...flatmap, {
            type,
            dataId: idGenerator(),
            widgetId: mandatory(section.value.widgetId, ''),
            language: mandatory(section.value.language, 'en_us'),
            teamUid: nonMandatory(section.value.teamUid),
            uniqueTeamId: nonMandatory(section.value.uniqueTeamId),
            playerId: nonMandatory(section.value.playerId),
            seasonId: nonMandatory(section.value.seasonId),
            uniqueTournamentID: nonMandatory(section.value.uniqueTournamentID),
            seasonType: nonMandatory(section.value.seasonType),
            pastGames: nonMandatory(section.value.pastGames),
            matchId: nonMandatory(section.value.matchId),
            statType: nonMandatory(section.value.statType),
          }];
        }
        default: {
          if (typeof section.value.html === 'string') {
            const mediaId = mandatory(section.value, {}, getMediaId(type));
            return [...flatmap, {
              type,
              dataId: idGenerator(),
              html: mandatory(section.value.html, ''),
              ...(mediaId && { mediaId }),
            }];
          }
          return [...flatmap, {
            ...mandatory(section.value, {}),
            type,
            dataId: idGenerator(),
          }];
        }
      }
    }, []);
  };

  let body = createPageData(receivedBody);

  if (tableOfContentsBlockPosition > -1) {
    body = updateTableOfContentsBlock(tableOfContentsBlockPosition, body);
  }

  if (relatedPosts) {
    body = addRelatedPostsBlock(relatedPosts, body);
  }

  if (relatedTopics) {
    body = addRelatedTopicsBlock(relatedTopics, body);
  }

  if (adsInBody) {
    body = addAdsBlocksToBody(body, adsInBody, shouldExpandInContentAds);
  }
  return body;
};
