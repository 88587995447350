import { Block, idGenerator } from '../idGenerator.utils';
import { RelatedPostsData } from './createRelatedPostsData.utils';

export const relatedPostsBlockType = 'relatedPosts';
export const MM_CONTENT_EMBED = 'mm-content-embed';
export const GROUP_OF_LINKS = 'GroupOfLinks';

export interface MmContentEmbedType extends Block{
  embedType?: string;
}

function isMmContentEmbed(block: Block): block is MmContentEmbedType {
  return block.type === MM_CONTENT_EMBED;
}

const getGroupOfLinksIndex = (body: Array<Block>) => {
  return body.findIndex(block => isMmContentEmbed(block) && block.embedType === GROUP_OF_LINKS);
};
export const addRelatedPostsBlock = (contentRecommendationsData: RelatedPostsData, body: Array<Block>) => {
  const { title, articles, sourceType } = contentRecommendationsData;
  const contentRecommendationsBlock = {
    type: relatedPostsBlockType,
    dataId: idGenerator(),
    title,
    articles,
    sourceType,
  };
  const groupOfLinksIndex = getGroupOfLinksIndex(body);
  const groupOfLinksPlacement = ((groupOfLinksIndex + 1) / body.length) * 100;
  if (body.length < 7 && groupOfLinksIndex >= 0) {
    return body;
  }
  if (groupOfLinksIndex < 0 || groupOfLinksPlacement < 70) {
    return [...body, contentRecommendationsBlock];
  }
  if (groupOfLinksPlacement >= 70) {
    const relatedPostsBlockPosition = Math.floor(groupOfLinksIndex / 2);
    return [...body.slice(0, relatedPostsBlockPosition), contentRecommendationsBlock, ...body.slice(relatedPostsBlockPosition, body.length)];
  }
  return body;
};
