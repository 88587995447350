import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Loader,
  ArticleDivider,
} from 'mm-ui-components';
import {
  ArticleComponentDataProps,
  FiniteScrollArticleComponentDataProps,
} from '../../templates/utils/articleComponent.utils';
import { ArticlePageViewEvent } from './ArticlePageViewEvent';
import { formatDate, getCoverType } from '../../templates/templates.utils';
import { createBlockNames } from '../../store/template/postPage/utils/createBlockNames';
import { fetchFiniteScrollArticles } from '../../store/template/postPage/postPage.actions';
import { PostPageDataType } from '../../store/template/postPage/types';
import { getExperiments } from '../../store/config/config.selectors';

interface FiniteScrollNextArticlesProps {
  articles: Array<FiniteScrollArticleComponentDataProps>;
  articleComponent: React.FunctionComponent<ArticleComponentDataProps>;
  postPageData: PostPageDataType;
  articlesUrls: Array<string>;
  loadingArticles: boolean;
  experiment: string;
}

interface ArticlePageViewEventWithMemoProps {
  article: FiniteScrollArticleComponentDataProps;
  numberOfArticleOnPage: number;
}

const ArticlePageViewEventWithMemoData: React.FunctionComponent<ArticlePageViewEventWithMemoProps> = props => {
  const { article, numberOfArticleOnPage, children } = props;
  const articleTemplateMemo = React.useMemo(() => createBlockNames(article.body), [article.body]);
  return (
    <ArticlePageViewEvent
      key={article.resourceID}
      articleId={article.resourceID}
      articleTemplate={articleTemplateMemo}
      channels={article.feeds}
      articleAuthor={article.ownerName}
      articleTitle={article.title}
      articleIntro={!!article.intro && article.intro.length !== 0}
      articlePublicationTime={formatDate(article.createdAtISO)}
      contentTags={article.tags}
      mainMedia={getCoverType(article.cover)}
      commercialTags={article.commercialTags}
      articleContentCount={article.articleContentCount}
      articleWordCount={article.articleWordCount}
      ownerUsername={article.ownerUsername}
      authorsUsernames={article.authorsUsernames}
      articleURL={article.articleUrl}
      createdAt={article.createdAtISO}
      updatedAt={article.updatedAtISO}
      isSlideshow={!!(article.slideshow && article.slideshow.slidesLinks)}
      mainCategoryEmbed={!!article.breadCrumbs?.categoryLink}
      numberOfArticleOnPage={numberOfArticleOnPage}
      topic={article.topic}
      vertical={article.vertical}
      experiments={useSelector(getExperiments)}
      seoTitle={article.seoTitle}
    >
      {children}
    </ArticlePageViewEvent>
  );
};
export const FiniteScrollNextArticles: React.FunctionComponent<FiniteScrollNextArticlesProps> = props => {
  const { articles, articleComponent, postPageData, articlesUrls, loadingArticles, experiment } = props;
  const [shouldRenderFiniteScrollArticles, setShouldRenderFiniteScrollArticles] = React.useState(false);
  const ArticleComponent = articleComponent;
  const dispatch = useDispatch();
  const [placeholderRef, setPlaceholderRef] = useState<HTMLDivElement | null>(null);
  const [articlesIndex, setArticlesIndex] = useState(-1);

  const INTERSECTION_THRESHOLD = 250;
  const NUM_OF_ARTICLES = 4;

  useEffect(() => {
    if (!shouldRenderFiniteScrollArticles && articlesUrls && articlesUrls.length) {
      setShouldRenderFiniteScrollArticles(true);
    }
  }, [articlesUrls, shouldRenderFiniteScrollArticles]);

  const onIntersect = useCallback((entries: any) => {
    const { intersectionRect, boundingClientRect } = entries[0];
    if (Math.abs(intersectionRect.bottom - boundingClientRect.bottom) <= INTERSECTION_THRESHOLD) {
      if (articlesIndex < NUM_OF_ARTICLES) setArticlesIndex(prevState => prevState + 1);
    }
  }, [articlesIndex]);

  useEffect(() => {
    if (articlesUrls && articlesUrls[articlesIndex]) {
      dispatch(fetchFiniteScrollArticles(articlesUrls && articlesUrls[articlesIndex], postPageData, experiment));
    }
  }, [articlesUrls, articlesIndex, dispatch, postPageData, experiment]);

  useEffect(() => {
    if (placeholderRef) {
      const observer = new IntersectionObserver(onIntersect);
      observer.observe(placeholderRef as unknown as HTMLElement);
      return () => {
        observer.disconnect();
      };
    }
    return () => null;
  }, [onIntersect, placeholderRef]);

  const updateRef = (element: HTMLDivElement) => {
    setPlaceholderRef(element);
  };

  return shouldRenderFiniteScrollArticles
    ? (
      <React.Fragment>
        {articles && articles.map((articleProps, index) => (
          <ArticlePageViewEventWithMemoData
            key={articleProps.resourceID}
            article={articleProps}
            numberOfArticleOnPage={index + 2}
          >
            <ArticleDivider />
            <ArticleComponent {...articleProps} />
          </ArticlePageViewEventWithMemoData>
        ))}
        {loadingArticles ? <Loader /> : <div ref={updateRef} className="placeholderFiniteScroll" />}
      </React.Fragment>
    )
    : null;
};
