import * as React from 'react';
import { FiniteScrollBrowserURLManager } from './FiniteScrollBrowserURLManager';
import { FiniteScrollNextArticles } from './FiniteScrollNextArticles';
import { ArticleComponentDataProps } from '../../templates/utils/articleComponent.utils';
import { FiniteScrollArticleData } from '../../store/template/postPage/utils/createFiniteScrollArticleData';
import { PostPageDataType } from '../../store/template/postPage/types';

interface FiniteScrollProps {
  articleComponent: React.FunctionComponent<ArticleComponentDataProps>;
  articles: Array<FiniteScrollArticleData> | null;
  articlesUrls: Array<string> | null;
  by: string;
  siteName: string;
  templateName: string;
  outbrainWidgetID: string;
  openWebId: string | null;
  shouldDisplayDisclaimerAtBottom: boolean;
  postPageData: PostPageDataType;
  loadingArticles: boolean;
  experiment: string;
  isMobileViewer: boolean;
}

interface ConfigDataForFiniteScrollArticle {
  by: string;
  siteName: string;
  templateName: string;
  outbrainWidgetID: string;
  openWebId: string | null;
  shouldDisplayDisclaimerAtBottom: boolean;
  experiment?: string;
  isMobileViewer: boolean;
}

const createFullArticleComponentDataPropsForFiniteScrollArticles = (finiteScrollArticles: Array<FiniteScrollArticleData> | null, configData: ConfigDataForFiniteScrollArticle) => {
  if (finiteScrollArticles) {
    return finiteScrollArticles.map(finiteScrollArticle => ({
      ...finiteScrollArticle,
      sideBar1Id: null,
      sideBar2Id: null,
      sideBar1AdOnSuccess: null,
      sideBar2AdOnSuccess: null,
      by: configData.by,
      siteName: configData.siteName,
      templateName: configData.templateName,
      outbrainWidgetID: configData.outbrainWidgetID,
      openWebId: configData.openWebId,
      shouldDisplayDisclaimerAtBottom: configData.shouldDisplayDisclaimerAtBottom,
      experiment: configData.experiment,
      isMobileViewer: configData.isMobileViewer,
    }));
  }
  return [];
};


export const FiniteScroll: React.FunctionComponent<FiniteScrollProps> = props => {
  const { children, articles, articleComponent, openWebId, by, outbrainWidgetID, siteName, templateName, shouldDisplayDisclaimerAtBottom, postPageData, articlesUrls, loadingArticles, experiment, isMobileViewer } = props;
  const articlesData = React.useMemo(() => createFullArticleComponentDataPropsForFiniteScrollArticles(articles, {
    by,
    siteName,
    templateName,
    outbrainWidgetID,
    openWebId,
    shouldDisplayDisclaimerAtBottom,
    experiment,
    isMobileViewer,
  }), [articles, by, openWebId, outbrainWidgetID, shouldDisplayDisclaimerAtBottom, siteName, templateName, experiment, isMobileViewer]);
  if (!articlesUrls || articlesUrls.length === 0) {
    return (
      <React.Fragment>
        {children}
      </React.Fragment>
    );
  }
  return (
    <FiniteScrollBrowserURLManager>
      {children}
      <FiniteScrollNextArticles articles={articlesData} articleComponent={articleComponent} postPageData={postPageData} articlesUrls={articlesUrls} loadingArticles={loadingArticles} experiment={experiment} />
    </FiniteScrollBrowserURLManager>
  );
};
