import { nonMandatory } from '../../../storeFromRequestParamsValidation';

export const DISCLAIMER_COMMERCIAL_TAG = 'disclaimer';
export const REVIEW_DISCLAIMER_COMMERCIAL_TAG = 'review-disclaimer';
export const NO_VIDEO_PLAYER_COMMERCIAL_TAG = 'no-video-player';
export const NO_RELATED_TOPICS_COMMERCIAL_TAG = 'no-related-topics';
export const NO_ADS = 'no-ads';
export const NO_LIVE_COMMENTS_COMMERCIAL_TAG = 'no-live-comments';
export const NO_HERO_IMAGE_COMMERCIAL_TAG = 'no-hero-image';

export const checkIfCommercialTagExists = (data: any, commercialTag: string) => {
  const commercialTags = nonMandatory(data.pageContent.article.metadata.commercialTags);
  return commercialTags ? commercialTags.includes(commercialTag) : false;
};
