import { mandatory } from '../../../storeFromRequestParamsValidation';
import { getViewBoxValue } from '../../../navigations/navigation.utils';

export const createSponsorData = (sponsor: any) => {
  const logo = mandatory(sponsor.image, '');
  return {
    logo,
    brandName: mandatory(sponsor.brandName, ''),
    logoViewBox: getViewBoxValue(logo),
    text: mandatory(sponsor.text, ''),
    href: mandatory(sponsor.url, ''),
  };
};
