import { Slideshow, SlideshowAMP } from '../postPage.reducer';
import { nonMandatory } from '../../../storeFromRequestParamsValidation';

interface SlideshowTexts {
  prevText: string;
  nextText: string;
  ofText: string;
}

export const getNumberOfSlides = (numberOfPageBreaks: number) => {
  return numberOfPageBreaks > 0 ? numberOfPageBreaks + 1 : 0;
};

export const getCurrentSlide = (currentSlide: number) => currentSlide || 1;

export const createSlideshowTexts = (slideshowPageData: any): SlideshowTexts => ({
  prevText: nonMandatory(slideshowPageData.prevText.value),
  nextText: nonMandatory(slideshowPageData.nextText.value),
  ofText: nonMandatory(slideshowPageData.ofText.value) || '/',
});

export const createSlideshowData = (slideshowArticleData: any, slideshowTexts: SlideshowTexts, experiments: Array<string> = []): Slideshow => {
  const { prevText, nextText, ofText } = slideshowTexts;
  let totalSlides = nonMandatory(slideshowArticleData.numberOfPageBreaks, getNumberOfSlides) || 0;
  const currentSlide = totalSlides > 0 ? nonMandatory(slideshowArticleData.currentSlide, getCurrentSlide) : 0;
  totalSlides = experiments.toString().includes('slideshow-test|test') ? 0 : totalSlides;
  const prevLink = nonMandatory(slideshowArticleData.prevLink);
  const nextLink = nonMandatory(slideshowArticleData.nextLink);
  const slidesLinks = nonMandatory(slideshowArticleData.slidesLinks);
  return {
    totalSlides,
    currentSlide,
    prevText,
    nextText,
    ofText,
    nextLink,
    prevLink,
    slidesLinks,
  };
};

export const createSlideshowDataAmp = (slideshowArticleData: any, slideshowTexts: SlideshowTexts): SlideshowAMP => {
  const anchorId = 'slideshow-top-bar';
  const prevLink = nonMandatory(slideshowArticleData.prevLink);
  const nextLink = nonMandatory(slideshowArticleData.nextLink);
  return {
    ...createSlideshowData(slideshowArticleData, slideshowTexts),
    nextLink: nextLink ? `${nextLink}#${anchorId}` : nextLink,
    prevLink: prevLink ? `${prevLink}#${anchorId}` : prevLink,
    anchorId,
  };
};
