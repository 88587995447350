import { ImageLink, LinkButton } from '../../navigation.utils';
import { INJECT_BUTTON_TO_FOOTER_LINKS_SECTION, InjectButtonToFooterLinksSectionAction } from '../../navigation.actions';

type Link = {
  text: string;
  href: string;
};

export interface NavigationLink {
  link: Link;
  children: NavigationLink | null;
}

export interface OldFooterState {
  linksSection: {
    title: string;
    links: Array<NavigationLink>;
    buttons?: Array<LinkButton>;
  } | null;
  oldSocialSection: {
     title: string;
     imageLinks: Array<ImageLink>;
  } | null;
  appsSection: {
     title: string;
     imageLinks: Array<ImageLink>;
  } | null;
  bottomFooterBar: {
     year: number;
     rightsReservedText: string;
     poweredByLink: Link;
  };
}

export type FooterAction = InjectButtonToFooterLinksSectionAction;

export const footerReducer = (state: OldFooterState = {} as OldFooterState, action: FooterAction) => {
  switch (action.type) {
    case INJECT_BUTTON_TO_FOOTER_LINKS_SECTION: {
       const { text, onClick = null, cssClassName = null, attributes = null } = action;
       const buttons = state.linksSection && state.linksSection.buttons;
       return {
         ...state,
         linksSection: {
           ...state.linksSection,
           buttons: [
             ...(buttons || []),
             {
               text,
               onClick,
               cssClassName,
               attributes,
             },
           ],
         },
       };
    }
    default:
      return state;
  }
};
