import { mandatory } from '../../../storeFromRequestParamsValidation';

const convertStringToNumber = (numberStr: string) => {
  const number = Number(numberStr);
  return Number.isInteger(number) ? number : null;
};

export const createAspectRatioObjectFromString = (aspectRatio: string) => {
  const widthAndHeight = aspectRatio.split('/');
  const x = mandatory(widthAndHeight[0], '16', convertStringToNumber);
  const y = mandatory(widthAndHeight[1], '9', convertStringToNumber);

  if ((x && y) || aspectRatio === 'original') {
    return {
      x,
      y,
      original: aspectRatio === 'original',
    };
  }
  return null;
};

export const createAspectRatioObject = (aspectRatio: any) => {
  return {
    x: mandatory(aspectRatio.x, 16),
    y: mandatory(aspectRatio.y, 9),
    original: aspectRatio === 'original',
  };
};

export const createAspectRatioData = (aspectRatio: any) => {
  return typeof aspectRatio === 'string' ? createAspectRatioObjectFromString(aspectRatio) : createAspectRatioObject(aspectRatio);
};
