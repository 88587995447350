import { mandatory, nonMandatory } from '../../../storeFromRequestParamsValidation';
import { createMainCategory } from './createMainCategory.utils';
import {
  createAuthors,
  createCoverVideo,
  createDate,
  createImage,
  createShortDateOrTime,
  createTimeAndDate,
} from '../../../articles.utils';
import { createSponsorData } from './createSponsorData';
import { createSlideshowData } from './slideshow.utils';
import { DISCLAIMER_COMMERCIAL_TAG } from './checkIfCommercialTagExists';
import { createPageBodyData } from './createPageBodyData';
import { createMD5Hash } from './createMD5Hash';
import { AuthorsList, Body, Cover, MainCategory, ShareConfig, Slideshow, SponsorData } from '../postPage.reducer';
import { PostPageDataType } from '../types';

export interface FiniteScrollArticleData {
  resourceID: string;
  feeds: Array<string> ;
  ownerName: string;
  commercialTags: Array<string> ;
  articleContentCount: { [key: string]: number };
  articleWordCount: number;
  mainCategory: MainCategory | null;
  title: string;
  intro: string | null;
  updatedText: string;
  updatedAt: string;
  updatedAtISO: string;
  showUpdatedAt: boolean;
  authors: AuthorsList | null;
  shareConfig: ShareConfig;
  cover: Cover;
  sponsor: null | SponsorData;
  slideshow: Slideshow;
  body: Body | null;
  showRecommendationsAdvertisement: boolean;
  tags: Array<string>;
  md5hashPostCanonicalUrl: string | null;
  articleUrl: string;
  ownerUsername: string;
  authorsUsernames?: Array<string>;
  createdAt: string;
  createdAtISO: string;
  disclaimerText: string | null;
  topic: string | null;
  vertical: string | null;
}

export const checkFiniteScrollArticleData = (data: any) => {
  return nonMandatory(data.pageContent.additionalPosts.postsList.value.articlesQueryURLs, (articles: any) => !!(articles && articles.length > 0)) || false;
};

export const createFiniteScrollArticleData = (article: any, imageCDNHost: any, midnightInTimeZone: string, timeZone: string, locale: string, editionEndpoint: string, postPageData: PostPageDataType, experiment: string) => {
  const { slideshowData, commercialTags: commercialTagsData, updatedText, disclaimerText: disclaimerTextData, showUpdatedAt } = postPageData;
  const commercialTag = commercialTagsData ? commercialTagsData.includes('no-finite-scroll') : false;
  if (commercialTag) {
    return null;
  }
    const hideOwner = nonMandatory(article.metadata.hideOwner);
    const defaultOwnerName = nonMandatory(article.authors.owner.name) || '';
    const defaultOwnerUsername = nonMandatory(article.authors.owner.username) || '';
    const createdDate = mandatory(article.createdAt, '', (articleDateISO: string) => createDate(locale, timeZone, articleDateISO));
    const updatedDate = mandatory(article.updatedAt, '', (articleDateISO: string) => createDate(locale, timeZone, articleDateISO));
    const shouldDisplayUpdatedAt = showUpdatedAt && (createdDate !== updatedDate);
    const commercialTags = nonMandatory(article.metadata.commercialTags) || [];
    const experiments = experiment ? experiment.split(',') : [];
    return [{
      resourceID: mandatory(article.id, ''),
      feeds: mandatory(article.feeds, ''),
      commercialTags: nonMandatory(article.metadata.commercialTags),
      articleContentCount: nonMandatory(article.analysis.contentTypesToQuantity),
      articleWordCount: nonMandatory(article.analysis.numberOfWordsTyped),
      mainCategory: nonMandatory(article.mainCategory, createMainCategory(editionEndpoint)),
      title: mandatory(article.title, ''),
      intro: nonMandatory(article.intro),
      updatedText: mandatory(updatedText, ''),
      updatedAt: mandatory(article.updatedAt, '', (articleDateISO: string) => createTimeAndDate(locale, timeZone, articleDateISO)),
      updatedAtISO: mandatory(article.updatedAt, ''),
      showUpdatedAt: shouldDisplayUpdatedAt,
      authors: nonMandatory(article.authors, createAuthors(hideOwner, imageCDNHost, editionEndpoint)),
      ownerName: mandatory(article.authors.owner.authorInfo.name.value, defaultOwnerName),
      ownerUsername: mandatory(article.authors.owner.userName, defaultOwnerUsername),
      shareConfig: [],
      cover: {
        image: mandatory(article.cover.image.value, {}, createImage(imageCDNHost)),
        media: nonMandatory(article.cover.media, createCoverVideo),
      },
      sponsor: nonMandatory(article.metadata.sponsor, createSponsorData),
      slideshow: createSlideshowData(article.slideshow, slideshowData, experiment.split(',')),
      showRecommendationsAdvertisement: !commercialTags.includes('no-external-recommendation'),
      body: nonMandatory(article.body, createPageBodyData(imageCDNHost, null, null, null, null)),
      tags: mandatory(article.tags, []),
      md5hashPostCanonicalUrl: mandatory(article.articleUrl, '', createMD5Hash),
      articleUrl: mandatory(article.articleUrl, ''),
      createdAt: mandatory(article.createdAt, '', createShortDateOrTime(midnightInTimeZone, timeZone, locale)),
      createdAtISO: mandatory(article.createdAt, ''),
      disclaimerText: commercialTags.includes(DISCLAIMER_COMMERCIAL_TAG) ? disclaimerTextData : null,
      topic: nonMandatory(article.customTopic),
      vertical: nonMandatory(article.customVertical),
      experiments: nonMandatory(experiments),
      seoTitle: nonMandatory(article.seoTitle),
    }];
};
