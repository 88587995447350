import React from 'react';

interface ElementData {
  URL: string;
  isVisible: boolean;
  top: number;
  title: string;
}

type BrowserURLManagerRegistration = (elementRef: React.RefObject<HTMLElement>, elementURL: string, elementTitle: string) => void;

const setCurrentOgTitleMetaTag = (elementTitle: string) => {
  const metaElement: HTMLMetaElement | null = document.querySelector("meta[property='og:title']");
  if (metaElement) metaElement.content = elementTitle.replace(/"/g, "'");
};

const setCurrentBrowserURL = (currentBrowserURL: string, articleElementsMap: Map<Element, ElementData>) => {
  try {
    let minTop = null as null | number;
    let visibleElementWithMinTopURL = currentBrowserURL as string;
    let visibleElementTitle = '';
    if (articleElementsMap) {
      articleElementsMap.forEach(elementData => {
        if (elementData.isVisible && (!minTop || elementData.top < minTop)) {
          minTop = elementData.top;
          visibleElementWithMinTopURL = elementData.URL;
          visibleElementTitle = elementData.title;
        }
      });
    }
    window.history.pushState(null, 'change URL', visibleElementWithMinTopURL);
    setCurrentOgTitleMetaTag(visibleElementTitle);
  } catch (e) {
    console.log('finite scroll error', e);
  }
};

const setTopForVisibleEntry = (entry: Element, top: number, articleElementsMap: Map<Element, ElementData>) => {
  const elementData = articleElementsMap.get(entry);
  if (elementData) {
    articleElementsMap.set(entry, {
      ...elementData,
      isVisible: true,
      top,
    });
    setCurrentBrowserURL(window.location.href, articleElementsMap);
  }
};
const setEntryInvisible = (entry: Element, articleElementsMap: Map<Element, ElementData>) => {
  const elementData = articleElementsMap.get(entry);
  if (elementData) {
    articleElementsMap.set(entry, {
      ...elementData,
      isVisible: false,
    });
    setCurrentBrowserURL(window.location.href, articleElementsMap);
  }
};

const getObserver = (articleElementsMap: Map<Element, ElementData>) => {
  if (typeof IntersectionObserver !== 'undefined') {
    return new window.IntersectionObserver(entries => entries.forEach(entry => {
      if (entry.isIntersecting) {
        setTopForVisibleEntry(entry.target, entry.boundingClientRect.top, articleElementsMap);
      } else {
        setEntryInvisible(entry.target, articleElementsMap);
      }
    }, {
      root: null,
      threshold: 0,
    }));
  }
  return null;
};


export const FiniteScrollBrowserURLManagerContext = React.createContext<null|BrowserURLManagerRegistration >(null);

export const useFiniteScrollBrowserURLManagerRegistration = (articleUrl: string, articleTitle: string) => {
  const ref = React.useRef(null);
  const finiteScrollBrowserURLManagerRegistration = React.useContext(FiniteScrollBrowserURLManagerContext);
  React.useEffect(() => {
    if (ref && ref.current && finiteScrollBrowserURLManagerRegistration) {
      finiteScrollBrowserURLManagerRegistration(ref, articleUrl, articleTitle);
    }
  }, [articleUrl, articleTitle, finiteScrollBrowserURLManagerRegistration, ref]);
  return ref;
};

export const FiniteScrollBrowserURLManager: React.FunctionComponent = props => {
  const { children } = props;
  const articleElementsMap = React.useMemo(() => new Map<Element, ElementData>(), []);
  const observer = React.useMemo(() => getObserver(articleElementsMap), [articleElementsMap]);

  const finiteScrollBrowserURLManagerRegistration = React.useCallback((elementRef: React.RefObject<HTMLElement>, elementURL: string, elementTitle: string) => {
    if (observer) {
      articleElementsMap.set(elementRef.current as HTMLElement, {
        URL: elementURL,
        isVisible: false,
        top: 0,
        title: elementTitle,
      });
      observer.observe(elementRef.current as HTMLElement);
    }
  }, [articleElementsMap, observer]);

  return (
    <FiniteScrollBrowserURLManagerContext.Provider value={finiteScrollBrowserURLManagerRegistration}>
      {children}
    </FiniteScrollBrowserURLManagerContext.Provider>
  );
};
