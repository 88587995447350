import React, { PropsWithChildren } from 'react';
import { Disclaimer } from 'mm-ui-components';
import { BlockWidths } from 'mm-ui-components/dist/src/base/components/pageLayouts/BlockTypes.utils';

interface DisclaimersAtDynamicPositionProps {
  disclaimerText: string | null;
  reviewDisclaimerText?: string | null;
  defaultBlocksWidths: BlockWidths;
  shouldDisplayDisclaimerAtBottom: boolean;
}
export const DisclaimersAtDynamicPosition = (props: PropsWithChildren<DisclaimersAtDynamicPositionProps>) => {
  const { children, disclaimerText, reviewDisclaimerText, defaultBlocksWidths, shouldDisplayDisclaimerAtBottom } = props;
  return (
    shouldDisplayDisclaimerAtBottom ? (
      <React.Fragment>
        {children}
        {disclaimerText && <Disclaimer text={disclaimerText} defaultBlocksWidths={defaultBlocksWidths} />}
        {reviewDisclaimerText && <Disclaimer text={reviewDisclaimerText} defaultBlocksWidths={defaultBlocksWidths} />}
      </React.Fragment>
    ) : (
      <React.Fragment>
        {disclaimerText && <Disclaimer text={disclaimerText} defaultBlocksWidths={defaultBlocksWidths} />}
        {reviewDisclaimerText && <Disclaimer text={reviewDisclaimerText} defaultBlocksWidths={defaultBlocksWidths} />}
        {children}
      </React.Fragment>
    )
  );
};
