import React from 'react';
import { ViewabilityElement } from 'mm-ui-components';
import { sendPageView } from 'mm-client-core';
import { useSelector } from 'react-redux';
import { ArticlePageViewParameters, ConfigPageViewParameters } from '../../templates/utils/articleComponent.utils';
import {
  getAdSlots,
  getEdition,
  getLanguage,
  getPageType,
  getSiteName,
  getAmpDfpProperty,
} from '../../store/config/config.selectors';
import { getCountryCode } from '../../templates/templates.utils';

interface ArticleUrlProp {
  articleURL: string;
}

const sendCustomEvent = (eventName: string, eventData: any) => {
  const customEvent = new CustomEvent(eventName, { detail: eventData });
  window.dispatchEvent(customEvent);
};

type ArticlePageViewEventProps = ArticlePageViewParameters & ArticleUrlProp
export const ArticlePageViewEvent: React.FunctionComponent<ArticlePageViewEventProps> = props => {
  const {
    children,
    ownerUsername,
    authorsUsernames,
    articleTitle,
    articleIntro,
    articleAuthor,
    articleContentCount,
    articleId,
    articlePublicationTime,
    articleTemplate,
    articleWordCount,
    channels,
    commercialTags,
    contentTags,
    dfpCategory,
    mainMedia,
    articleURL,
    createdAt,
    updatedAt,
    isSlideshow,
    mainCategoryEmbed,
    numberOfArticleOnPage,
    topic,
    vertical,
    experiments,
    seoTitle,
  } = props;
  const siteName = useSelector(getSiteName);
  const language = useSelector(getLanguage);
  const pageType = useSelector(getPageType);
  const property = useSelector(getEdition);
  const adSlots = useSelector(getAdSlots);
  const dfpProperty = useSelector(getAmpDfpProperty);

  const sendPageViewForArticle = React.useCallback(() => {
    const pageParameters: ArticlePageViewParameters & ArticleUrlProp & ConfigPageViewParameters = {
      ownerUsername,
      authorsUsernames,
      articleTitle,
      articleAuthor,
      articleContentCount,
      articleId,
      articlePublicationTime,
      articleTemplate,
      articleWordCount,
      channels,
      commercialTags,
      contentTags,
      dfpCategory,
      mainMedia,
      siteName,
      language,
      pageType,
      property,
      adSlots,
      countryCode: getCountryCode(),
      dfpProperty,
      articleURL,
      createdAt,
      updatedAt,
      isSlideshow,
      mainCategoryEmbed,
      numberOfArticleOnPage,
      vertical,
      topic,
      articleIntro,
      experiments,
      seoTitle,
    };
    try {
      if (window.location.href !== articleURL) {
        window.history.pushState(null, 'change URL', articleURL);
      }
    } catch (e) {
      console.log('finite scroll error', e);
    }

    sendCustomEvent('finiteScrollEvent', { message: 'Next Article On finite Scroll', articleId });
    sendPageView(pageParameters, 'GTM data ready to GA finite scroll');
  }, [ownerUsername, authorsUsernames, articleTitle, articleAuthor, articleContentCount, articleId, articlePublicationTime, articleTemplate, articleWordCount, channels, commercialTags, contentTags, dfpCategory, mainMedia, siteName, language, pageType, property, adSlots, dfpProperty, articleURL, createdAt, updatedAt, isSlideshow, mainCategoryEmbed, numberOfArticleOnPage, vertical, topic, articleIntro, experiments, seoTitle]);

  return (
    <ViewabilityElement viewabilityCallback={sendPageViewForArticle}>
      {children}
    </ViewabilityElement>
  );
};
