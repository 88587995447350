import { BlockWidths } from 'mm-ui-components/src/base/components/pageLayouts/BlockTypes.utils';

export const createBlocksWidth = (largeWidth: number, mediumWidth: number, smallWidth: number) => {
    return {
        largeWidth,
        mediumWidth,
        smallWidth,
    } as BlockWidths;
};
export const createDefaultBlocksWidths = () => {
    return createBlocksWidth(640, 730, 640);
};
