import {
    FAQBlock,
    HTMLBlock,
    ListItemTitleBlock,
} from 'mm-ui-components/dist/src/base/components/pageLayouts/BlockTypes.utils';
import { Block } from './idGenerator.utils';

const createHeadingFromTitleBlock = (block: ListItemTitleBlock) => ({ blockId: block.dataId, headingTitle: block.text });
const createHeadingFromFAQBlock = (block: FAQBlock) => ({ blockId: block.dataId, headingTitle: block.question });
const createHeadingFromInlineTextBlock = (block: HTMLBlock) => {
    const regex = /<h2>(.*)<\/h2>/;
    const match = block.html.match(regex);
    return { blockId: block.dataId, headingTitle: match ? match[1] : '' };
};
const isInlineTextHeading = (block: Block) => {
    const htmlBlock = block as HTMLBlock;
    return block.type === 'inline-text' && htmlBlock.html.startsWith('<h2>') && htmlBlock.html.endsWith('</h2>');
};

const extractHeadingsFromBody = (body: Array<Block>) => {
    return body.map(block => {
        switch (block.type) {
            case 'title': return createHeadingFromTitleBlock(block as ListItemTitleBlock);
            case 'faq': return createHeadingFromFAQBlock(block as FAQBlock);
            case 'inline-text': return isInlineTextHeading(block) ? createHeadingFromInlineTextBlock(block as HTMLBlock) : null;
            default: return null;
        }
    }).filter(heading => !!heading);
};

export const updateTableOfContentsBlock = (blockPosition: number, body: Array<Block>) => {
    const headings = extractHeadingsFromBody(body.slice(blockPosition));
    const tableOfContentsBlock = { ...body[blockPosition], headings };
    if (headings.length) {
        return [...body.slice(0, blockPosition), tableOfContentsBlock, ...body.slice(blockPosition + 1, body.length)];
    }
    return [...body.slice(0, blockPosition), ...body.slice(blockPosition + 1, body.length)];
};
