import React from 'react';
import { useSelector } from 'react-redux';
import { ErrorBoundary, PostMetaHeaderTags } from 'mm-ui-components';
import { PageImage } from 'mm-ui-components/dist/src/base/metaHeaderTags/metaTags.utils';
import {
  getAmpURL,
  getCanonicalUrl,
  getFacebookAppId,
  getFacebookPageId,
  getFavicon,
  getLogo,
  getPrevCanonicalUrl,
  getSiteName,
  getTwitterAccount,
} from '../../store/config/config.selectors';

interface PostMetaTagsProps {
  pageDescription: string;
  seoTitle: string;
  pageImage: PageImage;
  authors: { name: string }[] | null;
  createdAtISO: string;
  updatedAtISO: string;
  noIndex: boolean;
  ogDescription: string;
  ogTitle: string;
  ogImage: PageImage;
  twitterDescription: string;
  twitterTitle: string;
  twitterImage: PageImage;
  seoDescription: string;
  articleSection: string;
  prevSlideshowUrl: string | null;
  nextSlideshowUrl: string | null;
  tags: string[];
  breadCrumbs?: {
    homeDisplayName: string;
    homeLink: string;
    categoryDisplayName: string;
    categoryLink: string;
  } | null;
  faqData: any[];
  liveBlogData?: Record<string, string>[];
  articleSchemaVideo?: Record<string, any>;
}

export const PostMetaTagsConnected: React.FunctionComponent<PostMetaTagsProps> = props => {
  const facebookAppId = useSelector(getFacebookAppId);
  const facebookPageId = useSelector(getFacebookPageId);
  const siteName = useSelector(getSiteName);
  const canonicalUrl = useSelector(getCanonicalUrl);
  const favicon = useSelector(getFavicon);
  const twitterAccount = useSelector(getTwitterAccount);
  const logo = useSelector(getLogo);
  const ampURL = useSelector(getAmpURL);
  const prevCanonicalUrl = useSelector(getPrevCanonicalUrl);
  const { pageDescription, seoTitle, pageImage, authors, createdAtISO, updatedAtISO, noIndex, ogDescription, ogTitle, ogImage, twitterDescription,
  twitterTitle, twitterImage, seoDescription, articleSection, prevSlideshowUrl, nextSlideshowUrl, tags, breadCrumbs, faqData, liveBlogData, articleSchemaVideo } = props;
  return (
    <PostMetaHeaderTags {...{
      facebookAppId,
      facebookPageId,
      pageDescription,
      siteName,
      canonicalUrl,
      favicon,
      seoTitle,
      twitterAccount,
      pageImage,
      logo,
      authors,
      createdAtISO,
      updatedAtISO,
      ampURL,
      prevUrl: prevSlideshowUrl || prevCanonicalUrl,
      nextUrl: nextSlideshowUrl,
      noIndex,
      ogDescription,
      ogTitle,
      ogImage,
      twitterDescription,
      twitterTitle,
      twitterImage,
      seoDescription,
      articleSection,
      tags,
      breadCrumbs,
      faqData,
      liveBlogData,
      articleSchemaVideo,
    }}
    />
  );
};
export const PostMetaTags: React.FunctionComponent<PostMetaTagsProps> = props => <ErrorBoundary><PostMetaTagsConnected {...props} /></ErrorBoundary>;
